body, html {
	margin: 0;
	height: 100%;
}
.code-panel {
	text-align: left;
	font-size: 1rem;
	height: 100%;
}
.code-panel section {
	height: 50%;
}
.code-panel section > div:not(.CodeMirror) {
	line-height: 20px;
	font-size: 13px;
	padding-left: 0.6rem;
	color: #aaa;
	/* user-select: none;
	cursor: ns-resize; */
}
.gutter {
	cursor: ns-resize;
	position: relative;
	background-color: rgba(0, 0, 0, 0.2);
}
.gutter:after {
	content: "";
	display: block;
	height: 8px;
	width: 100%;
	position: absolute;
	top: -3px;
	z-index: 150;
}
.code-panel .CodeMirror {
	height: calc(100% - 20px);
}
.gjs-pn-views {
	border-left: 1px solid rgba(0,0,0,0.2);
	border-bottom: 0;
}
.gjs-pn-views-container {
	box-shadow: initial;
	border-top: 2px solid rgba(0,0,0,0.2);
	top: 40px;
	padding-top: 0;
	height: calc(100vh - 40px);
}
.gjs-pn-views-container, .gjs-cv-canvas {
	transition: width 0.3s ease-in-out;
}
