/* Let's highlight canvas boundaries */
#gjs {
  border: 3px solid #444;
  width: 100%;
}

/* Reset some default styling */
/* .gjs-cv-canvas {
  /* top: 0; */
/* width: 100%; */
/* height: 100%; */
/* } */

.gjs-block {
  width: 100%;
  height: 100%;
  min-height: auto;
}

.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
}
.panel__basic-actions {
  position: initial;
}

.editor-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  height: 300px;
}

.editor-canvas {
  flex-grow: 1;
}

.panel__right {
  flex-basis: 230px;
  position: relative;
  overflow-y: auto;
}

.panel__switcher {
  position: initial;
}

.panel__devices {
  position: initial;
}

/* We can remove the border we've set at the beginnig */
#gjs {
  border: none;
}
/* Theming */

/* Primary color for the background */
.gjs-one-bg {
  background-color: white;
}

/* Secondary color for the text color */
.gjs-two-color {
  color: black;
}

/* Tertiary color for the background */
.gjs-three-bg {
  background-color: #b7d5f9;
  color: white;
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
  color: #4d76a8;
}

/* .gjs-editor-cont {
  width: "150% !important";
} */

/* .wrapper {
  padding-top: "40px";
} */

/* .gjs-row {
  padding-top: "45px";
} */
