@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@700&display=swap");

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.auth {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  flex-direction: row;
  align-items: stretch;
  background-size: cover;
}

.saleText {
  position: absolute;
  margin: 0 auto;
  padding: 0 1.75rem;
  bottom: 3rem;
  left: 0;
  right: 0;
  font-family: "Nunito Sans", sans-serif;
  color: white;
}

.saleTextFirst {
  font-size: 1.125rem;
  margin: 0 auto 20px;
  max-width: 700px;
  font-weight: 400;
  font-family: Nunito, sans-serif;
}

.logoFirst {
  text-align: center;
  padding-top: 10px;
}

.logoExterno {
  width: 25%;
  height: auto;
  border-radius: 22%;
  text-align: center;
  /* background: #d4453a; */
  margin-left: 15px;
}

.auth .form-control {
  font-size: 12px;
}

.custom-control-label {
  padding-top: 0px;
}

.auth p {
  margin-bottom: 2rem;
}
