.dx-button-has-text .dx-button-content {
  padding: 0px;
}

.dx-texteditor-container {
  height: 28px;
}

.redHeight .dx-texteditor-container {
  height: unset;
}

.redHeight2 .dx-texteditor-container {
  height: inherit;
}

.redHeight {
  margin-top: 10px;
}

.dx-field {
  margin: 0 0 10px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

@media screen and (max-width: 1600px) {
  .dx-field {
    font-size: 12.5px;
    text-align: left;
  }
}

.dx-texteditor-input-container {
  top: -2px;
}

/* Tag com nome dos estabelecimentos */
body .coupon .custom-item {
  padding-left: 7px;
  padding-right: 7px;
}
.coupon .custom-item > img {
  height: 30px;
  width: 40px;
  float: left;
  /* margin-top: 2px; */
}
.coupon .custom-item > div.product-name {
  margin-left: 40px;
  line-height: 34px;
  font-size: 14px;
}

body .coupon .custom-item input {
  background-color: transparent;
}

body .coupon .dx-popup-content .custom-item {
  padding-top: 7px;
  padding-bottom: 8px;
}
.coupon .dx-popup-content .custom-item > div {
  padding-left: 8px;
  text-indent: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.coupon .dx-tag-content {
  padding: 3px 25px 2px 6px;
}

.coupon .form-avatar {
  height: 40px;
  width: 40px;
  /* margin-right: 10px; */
  margin-bottom: 10px;
  border: 1px solid #d2d3d5;
  border-radius: 50%;
  background-image: url("../../../images/complet_logo_2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

/* .dx-field-value.code {
    font-family: Impact !important;
    font-weight: bolder;
    font-size: 18px;
    background: yellow;
} */

/* .dx-field {
    color: #333;
    font-weight: 400;
    font-size: 12.5px;
    font-family: 'Helvetica Neue','Segoe UI',Helvetica,Verdana,sans-serif;
    line-height: 1.35715;
    min-height: 36px;
    padding: 0;
} */
