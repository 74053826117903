.custom .dx-datagrid .dx-row > td {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 16px;
}

.custom .dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
  font-weight: 500;
  background-color: #f9b831;
  color: #fff;
}

.custom .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 7px 9px 8px 34px;
  line-height: 0;
}

.custom .dx-widget {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto, RobotoFallback, "Noto Kufi Arabic", Helvetica, Arial,
    sans-serif;
  line-height: 0;
}

.custom .dx-texteditor-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
  padding: 5px;
}

.buttonClass a {
  margin-left: 8px;
}

.dx-focused {
  border: 1px solid red !important;
}
