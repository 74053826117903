html {
  box-sizing: border-box;
  min-height: 100vh;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-height: 100vh;
  /* background-color: #f4a01da1;
  overflow: hidden;
  margin: 0;
  padding: 0; */
}

.cardAdd {
  max-height: 868px;
  /* border-radius: 30px; */
  border-color: white;
}

.supMiddle {
  height: 96vh;
  z-index: 1000;
}
/* div.topbar  {
  height: 55px;
  background-color: whitesmoke;
  margin-top: 10px;
} */

/* https://gist.github.com/ocean90/1268328 */
/* .leftMenu {
  border-right: solid 1px whitesmoke; 
  height: 800px;
  position: absolute;
  width: calc(100% - 11px);
  text-align: center;
  padding-top: 15px;
  border-radius: 15px;
} */

/* .middleBody {
  background-color: whitesmoke;
  height: 718px;
  margin-top: 27px;
} */

/* .supportMenu {
  background-color: whitesmoke;
  margin-top: 10px;
  height: 800px;
} */

img.logo {
  width: 44px;
  height: auto;
  padding-top: 10px;
}

img.logoLarge {
  margin-left: -1;
  width: 67%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

a:hover {
  color: #185b90 !important;
  text-shadow: 0px 0 0 #1e81ce !important;
}

/* .firstBlockMenu {
  height: 80px;
  margin-top: 0px;
}

.secondBlockMenu {
  margin-top: 35px;
  margin: 0 auto;
}

.item {
  padding-right: 6px;
} */

@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }
}

/* -----
SVG Icons - svgicons.sparkk.fr
----- */

.svg-icon {
  width: 28px;
  height: 28px;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: #755ff5;
}

.svg-icon circle {
  stroke: #755ff5;
  stroke-width: 1;
}

/* Style 2 */
* {
  /* margin: 0;
  padding: 0;
  font-family: sans-serif; */
  list-style-type: none;
  text-decoration: none;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .btn:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  background: #3498db;
  left: 20px;
  bottom: -7px;
  transform: rotate(45deg);
} */

/* svg:hover {
  -webkit-animation: neon 1.5s ease-in-out infinite alternate; 
}
@-webkit-keyframes neon { 
  from {
    text-shadow: 0 0 10px #fff,
    0 0 20px  #fff,
    0 0 30px  #fff,
    0 0 40px  #FF9900,
    0 0 70px  #FF9900,
    0 0 80px  #FF9900,
    0 0 100px #FF9900,
    0 0 150px #FF9900;
  }
  to {
    text-shadow: 0 0 5px #fff,
    0 0 10px #fff,
    0 0 15px #fff,
    0 0 20px #FF9900,
    0 0 35px #FF9900,
    0 0 40px #FF9900,
    0 0 50px #FF9900,
    0 0 75px #FF9900;
  }
} */

/* .smenu a:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 100%;
  background: #3498db;
  left: 0;
  top: 0;
  transition: 0.3s;
  opacity: 0;
}

.smenu a:hover:before {
  opacity: 1;
} */

/* .specialClass {
  max-height: 20em;
  color: black;
}

.item_:target .smenu {
  max-height: 10em;
} */

/* .dx-overlay-content { top: 150px!important} 
.dx-popup-normal { top: 100px!important} 
.dx-popup-draggable { top: 100px!important} 
.dx-resizable { top: 100px!important} */

/* Remove o fundo do Card que aparece quando usa o grid do DeveXtreme */
.dx-overlay-shader {
  background-color: unset;
}

.likeLink {
  color: #727cf5;
  background-color: transparent;
  transition: background-position 275ms ease;
  transition-property: background-position;
  transition-duration: 275ms;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.likeLink:hover {
  color: #171e6d;
  cursor: pointer;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #755ff5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  color: white;
}

/* .dx-datagrid {
  color: rgb(0 0 0);
  background-color: #fff;
  text-rendering: optimizeLegibility;
  font-size: 14px;
} */