.container-settings {
  display: flex;
  flex-direction: column;
  padding: 2em;
}


.subtitle-settings {
  font-size: 1rem;
  color: #494949;
}

.title-settings {
  font-size: 1.35rem;
  margin: 0.3rem 0 3rem 0;
  color: #494949;
}

.cards-container-settings {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
}


.card-settings {
  box-sizing: border-box;
  padding: 3rem 1rem;
  background: #fff;
  border: 1px solid #ccc;
  backdrop-filter: blur(6px);
  border-radius: 17px;
  text-align: center;
  cursor: pointer;
  font-size: 1.25em;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: black;
}

.card-settings:hover {
  border: 1px solid black;
  transform: scale(1.05);
  border-radius: 14px;
}

.card-settings:active {
  transform: scale(0.95) rotateZ(1.7deg);
}

.card-config-app {
  cursor: pointer;
  transition-duration: 0.2s;
}

.card-config-app:hover {
  background-color: #fafafa;
  border: 1px solid #494949
}

.disabled-card {
  opacity: 0.5; /* Faz com que o item fique mais apagado */
  color: #aaa;  /* Altera a cor para um tom mais claro */
  pointer-events: none; /* Desativa interações com o item */
}


