@font-face {
  font-family: 'Roboto';
  src: 
  url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
  url('./fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: 
  url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: 
  url('./fonts//Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: 
  url('./fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: 
  url('./fonts/Roboto-ThinItalic.ttf') format('truetype'); 
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: 
  url('./fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: 
  url('./fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: 
  url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: 
  url('./fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: 
  url('./fonts/NunitoSans-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: bold;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Nunito Sans', sans-serif,
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Nunito Sans', 'Courier New',
  monospace;
}

* {
  font-family: Roboto, 'Nunito Sans', sans-serif;  
}

.standardButton {
  height: 38px;
  padding: 8px;
  font-size: 14px;
}


