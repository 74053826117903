.first-group,
.second-group {
  padding: 20px;
}
.second-group {
  background-color: rgba(191, 191, 191, 0.15);
}
.form-avatar {
  height: 64px;
  width: 64px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #d2d3d5;
  border-radius: 50%;
  background-image: url("../../images/complet_logo_2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.branch .dx-field {
  margin-bottom: 0px !important;
}

.branch .custom-file-input ~ .custom-file-label::after {
  content: "Upload" !important;
  background-color: #f5f5f5;
  color: #333333;
}
