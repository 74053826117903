/* .tabbable .nav-tabs {
  overflow-x: auto;
  overflow-y:hidden;
  flex-wrap: nowrap;
}
.tabbable .nav-tabs .nav-link {
 white-space: nowrap;
} */

.tabbable {
  width: 97%;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #b7d5f9;
}

btn-info {
  color: #fff;
  background-color: #755ce6;
  border-color: #755ce6;
}

/* .custom-file-input ~ .custom-file-label::after {
  content: "Carregar" !important;
  background-color: #f77e53;
  color: white;
} */

.branch .custom-file-input ~ .custom-file-label::after {
  content: "Upload" !important;
  background-color: #f5f5f5;
  color: #333333;
}
