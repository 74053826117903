.middleBody {
  /* background-color: whitesmoke; */
  height: 85vh;
  margin-top: 8px;
  min-height: 82vh;
  /* padding-left: 20%; */
  /* padding-bottom: 15px; */
  padding: 5px;
  overflow: auto;
}

.removescroll {
  overflow: unset !important;
}

.middleLarge {
  width: 139%;
}
