.modallanding {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 125px !important;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
    width: 95% !important;
    }

      /* @media (min-width: 576px)
      {
      .modal-dialog {
        max-width: 94%;
        margin: 1.75 rem auto;
      }
      } */
      
      .modal-open {
        overflow: hidden;
      }
      
      .modal-open .modal {
        overflow-x: hidden;
        overflow-y: auto;
      }
      
      .modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1050;
        display: none;
        overflow: hidden;
        outline: 0;
      }
      
      .modal-dialog {
        position: relative;
        width: auto;
        margin: 0.5rem;
        pointer-events: none;
      }
      
      .modal.fade .modal-dialog {
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
        -webkit-transform: translate(0, -25%);
        transform: translate(0, -25%);
      }
      
      @media screen and (prefers-reduced-motion: reduce) {
        .modal.fade .modal-dialog {
          transition: none;
        }
      }
      
      .modal.show .modal-dialog {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
      }
      
      .modal-dialog-centered {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        min-height: calc(100% - (0.5rem * 2));
      }
      
      .modal-dialog-centered::before {
        display: block;
        height: calc(100vh - (0.5rem * 2));
        content: "";
      }
      
      .modal-content {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: 0;
      }
      
      .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1040;
        background-color: #000;
      }
      
      .modal-backdrop.fade {
        opacity: 0;
      }
      
      .modal-backdrop.show {
        opacity: 0.5;
      }
      
      .modal-header {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: start;
        align-items: flex-start;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid #e9ecef;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
      }
      
      .modal-header .close {
        padding: 1rem;
        margin: -1rem -1rem -1rem auto;
      }
      
      .modal-title {
        margin-bottom: 0;
        line-height: 1.5;
      }
      
      .modal-body {
        position: relative;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1rem;
      }
      
      .modal-footer {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 1rem;
        border-top: 1px solid #e9ecef;
      }
      
      .modal-footer > :not(:first-child) {
        margin-left: .25rem;
      }
      
      .modal-footer > :not(:last-child) {
        margin-right: .25rem;
      }
      
      .modal-scrollbar-measure {
        position: absolute;
        top: -9999px;
        width: 50px;
        height: 50px;
        overflow: scroll;
      }
      
      @media (min-width: 576px) {
        .modal-dialog {
          max-width: 500px;
          margin: 1.75rem auto;
        }
        .modal-dialog-centered {
          min-height: calc(100% - (1.75rem * 2));
        }
        .modal-dialog-centered::before {
          height: calc(100vh - (1.75rem * 2));
        }
        .modal-sm {
          max-width: 300px;
        }
      }
      
      @media (min-width: 992px) {
        .modal-lg {
          max-width: 800px;
        }
      }
      
      .tooltip {
        position: absolute;
        z-index: 1070;
        display: block;
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        line-break: auto;
        font-size: 0.875rem;
        word-wrap: break-word;
        opacity: 0;
      }
      
      .tooltip.show {
        opacity: 0.9;
      }
      
      .tooltip .arrow {
        position: absolute;
        display: block;
        width: 0.8rem;
        height: 0.4rem;
      }
      
      .tooltip .arrow::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
      }
      
      .bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
        padding: 0.4rem 0;
      }
      
      .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
        bottom: 0;
      }
      
      .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
        top: 0;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: #000;
      }
      
      .bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
        padding: 0 0.4rem;
      }
      
      .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
        left: 0;
        width: 0.4rem;
        height: 0.8rem;
      }
      
      .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
        right: 0;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-right-color: #000;
      }
      
      .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
        padding: 0.4rem 0;
      }
      
      .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
        top: 0;
      }
      
      .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
        bottom: 0;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: #000;
      }
      
      .bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
        padding: 0 0.4rem;
      }
      
      .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
        right: 0;
        width: 0.4rem;
        height: 0.8rem;
      }