.status-icon {
    height: 16px;
    width: 16px;
    display: inline-block;
    margin-right: 8px;
}

.middle_ {
    vertical-align: middle;
}
