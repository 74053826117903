.dx-switch-off {
    padding-left: 2px;
    color: #152eee;
}
    
.dx-switch-handle:before {
    background-color: #152eee !important;
}

.middleBody {
    height: 89vh;
}

.dxc-scroll-bar rect {
    fill: #755ff5;
    width: 6px;
    height: 100;
}

.dx-radiogroup-horizontal .dx-radiobutton {
    margin-right: 4px;
}

.dx-header-row > td[role="columnheader"]> div.dx-datagrid-text-content {  
    /* font-size: 17px;   */
    font-weight: bold;  
    color: rgb(35, 35, 35)
}

.dx-popup-content {
    z-index: 1501; /* Certifique-se de que está acima de overlays */
    position: relative; /* Garante que não seja bloqueado */
    overflow-y: auto; /* Rolagem habilitada */
    pointer-events: auto; /* Permite interações */
  }

  .dx-popup-content {
    overflow-y: auto; /* Habilita a rolagem no eixo Y */
    max-height: 100%; /* Garante que a altura seja apropriada para o conteúdo */
  }

  .dx-popup-wrapper {
    pointer-events: auto; /* Certifique-se de que o popup permita eventos do mouse */
  }
  
  .dx-popup-content {
    overflow-y: scroll; /* Garante a rolagem */
    max-height: 100%; /* Para que o conteúdo nunca exceda o popup */
  }

  /* Certifique-se de que a rolagem seja visível e funcione */
.dx-popup-content {
    overflow-y: scroll !important; /* Forçar a rolagem vertical */
    max-height: 100% !important;
    position: relative;
  }
  
  

