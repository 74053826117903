.topbar  {
    height: 62px;
    margin-top: 10px;
    /* padding: 25px; */
  }
  
  .topbarContainer {
    margin: 3px;
    /* float: right; */
  }
  
  .icons-list-demo i {
    text-align: center;
    vertical-align: middle;
    font-size: 22px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin-right: 12px;
    border-radius: 3px;
    display: inline-block;
    transition: all .2s;
  }
  
  /* a {
    color: #727cf5;
    background-color: transparent;
  } */
  
  canvas {
    border: 1px solid #999;
  }
  