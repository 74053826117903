
.myCSS .dx-texteditor-container {
    height: 48px !important;
}

.myCSS .dx-widget input, .myCSS .dx-widget textarea {
    /* font-family: Impact; */
    font-size: x-large;
    font-weight: bolder;
}

.myCSS .dx-show-clear-button .dx-icon-clear {
    color: #999;
    position: absolute;
    top: 30%;
    margin-top: -19px;
    width: 28px;
    height: 34px;
    background-position: 8px 8px;
    background-size: 18px 18px;
    padding: 8px;
    font-size: 14px;
    text-align: right;
    line-height: 18px;
}

.myCSS .dx-show-clear-button .dx-clear-button-area {
    width: 24px;
    min-width: 24px;
    right: 0;
}

.myCSS2 .dx-field-label {
    font-weight: bolder;
}

.myCSS .dx-field-label {
    padding: 6px 15px 0px 0;
}

.card-body .validation {
    padding-top: 12px;
    padding-bottom: 3px;
}

.validation .dx-field {
    margin-bottom: 3px !important;
}

hr.validation {
    margin: 6px 0px 6px 0px;
}

.custom-control-label {
    padding-top: 0px;
}

@media (min-width: 1500px)
{
.myCSS .col-xl-12 {
    flex: 0 0 66%;
    max-width: 66%;
}
}
