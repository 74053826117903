.supportMenu {
  background-color: whitesmoke;
  /* height: 718px; */
  margin-top: 8px;
}

#chart {
  height: 440px;
}

.tooltip-header {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 1px solid #c5c5c5;
}

.tooltip-body {
  width: 170px;
}

.tooltip-body .series-name {
  font-weight: normal;
  opacity: 0.6;
  display: inline-block;
  line-height: 1.5;
  padding-right: 10px;
  width: 126px;
}

.tooltip-body .value-text {
  display: inline-block;
  line-height: 1.5;
  width: 30px;
}

/*
::-webkit-scrollbar-thumb {
  background: unset !important; Deixar o scroll bar invisível 
  border: 0px none #ffffff;
  border-radius: 0px;
}
*/