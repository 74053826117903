.form-group {
  margin-bottom: 10px;
}

.dx-field-item-label {
  align-self: "center";
  color: "blue"
}

::-webkit-scrollbar-thumb {
   background: #8b75ee !important;
 }

 /* ::-webkit-scrollbar-thumb.on {
  background: #8b75ee !important;
} */

/* .content::-webkit-scrollbar-thumb {
  background: #b7d5f9 !important;
  border: 0px none #ffffff;
  border-radius: 0px;
} */