
.shadowCustom {
   box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, .9) !important; 
}

div.borderClass {
   border: 1px solid #ffffff;
   padding: 4px !important;
}

div.borderClass:hover {
   border: 1px solid #8080808a;
   cursor: pointer;
}

/* div.borderClass:active {
   border: 1px solid #3F51B5;
   cursor: pointer;
} */

div.clickedClass {
   border: 1px solid #3F51B5;
}

.clickedCoupon {
   border: 3px solid #3F51B5;
}
