.dx-field {
    margin-bottom: 10px !important;
}

.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
    padding-left: 7px;
    padding-right: 7px;
}

.custom-item {
    position: relative;
    min-height: 30px;
}

.dx-dropdowneditor-input-wrapper .custom-item > img {
    padding-left: 8px;
}

.custom-item .product-name  {
    display: inline-block;
    padding-left: 50px;
    text-indent: 0;
    line-height: 30px;
    font-size: 15px;
    width: 100%;
}

.custom-item > img {
    left: 1px;
    position: absolute;
    top: 50%;
    margin-top: -15px;
}

img {
    max-width: 100%;
}

.dx-theme-material #custom-templates .dx-texteditor-buttons-container {
    display: none;
}

.current-value {
    padding: 10px 0;
}

.current-value > span {
    font-weight: bold;
}

.dx-theme-material .dx-selectbox-container .product-name {
    padding-left: 58px;
}


/* HTML Editor */
.dx-htmleditor-content img {
    vertical-align: middle;
    /* padding-right: 10px; */
}

.value-content {
    margin-top: 20px;
    overflow: auto;
    height: 110px;
    width: 100%;
    white-space: pre-wrap;
}

.options {
    margin-top: 20px;
    padding: 20px;
    background-color: rgba(191, 191, 191, 0.15);
    box-sizing: border-box;
    width: 100%;
}
