.author {
  position: relative;
  /* margin-top: 11px; */
  color: white !important;
  float: right;
  top: 64px;
  left: 8px;
  font-size: 8px;
  height: 12px;
  font-family: Roboto;
  background: #3f51b58c;
  border-radius: 4px;
  padding: 0px 2px 0px 2px;
  opacity: 0;
}

/* .author:hover {
    opacity: 1;
} */

.imageDiv:hover .author {
  opacity: 1;
}

/* Efeito legal de xadres igual quando o fundo é png transparente, mas conflita com o uso da image abaixo como background */
.chess {
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
    linear-gradient(-45deg, #808080 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #808080 75%),
    linear-gradient(-45deg, transparent 75%, #808080 75%) !important;
  background-size: 20px 20px !important;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px !important;
}

.imageDiv {
  /* width: 33% !important; */
  width: 150px;
  /* min-height: 80px !important; */
  cursor: pointer !important;
  background-position: center !important;
  background-size: cover !important;
  background-color: #a4a4d821 !important;
  /* border-radius: 5px; */
  /* border-width: 1px; border-color: rgb(160,160,255);
    border-style: solid; */
  /* padding-right: 7px;
    padding-left: 7px; */
}

.delete {
  float: right;
  padding-right: 3px;
  color: #d60000 !important;
  font-weight: 700;
  padding-top: 1px;
}
