.nav-user .account-user-avatar {
  position: absolute;
  top: 8px;
  left: 15px;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.navbar-custom .topbar-right-menu li.show .nav-link {
  color: #6c757d;
}
.navbar-custom .topbar-right-menu .nav-link {
  padding: 0;
  color: #98a6ad;
  min-width: 32px;
  display: block;
  text-align: center;
  margin: 0 10px;
}
.nav-user {
  padding: 8px 0px 16px 70px !important;
  text-align: left !important;
  position: relative;
  /* background-color: #fafbfd; */
  /* border: 1px solid #f1f3fa; */
  /* border-width: 0 1px; */
  height: 60px !important;
}
.dropdown-toggle {
  white-space: nowrap;
}
a {
  /* color: #727cf5; */
  color: #676666;
  background-color: transparent;
}

.navbar-custom .topbar-right-menu .nav-link {
  padding: 0;
  /* color: #98a6ad; */
  min-width: 32px;
  display: block;
  text-align: center;
  margin: 0 10px;
}

/* .nav-user {
    padding: 16px 20px 16px 57px!important;
    text-align: left!important;
    position: relative;
    background-color: #fafbfd;
    border: 1px solid #f1f3fa;
    border-width: 0 1px;
    min-height: 70px;
} */
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.dropdown-toggle {
  white-space: nowrap;
}
a,
button {
  outline: none !important;
}
a,
a:hover {
  text-decoration: none;
}
a {
  /* color: #727cf5; */
  background-color: transparent;
}
*,
:after,
:before {
  box-sizing: border-box;
}

.rounded-circle {
  border-radius: 50% !important;
}

.nav-user .account-user-avatar img {
  height: 44px;
  width: 46px;
}

.topbarContainer.alternative {
  /* padding-top: 5px;
    padding-left: 5px; */
}

.svgStyle {
  fill: white;
  padding-bottom: 3px;
}

.pathStyle {
  fill: #e4dede;
}

.badge-success.alter {
  /* border: 1px solid #1e81ce; */
  border: 1px solid #676666;
  /* color: #94c5fc; */
  /* color: #1e81ce; */
  color: #676666;
  background-color: white;
  /* -webkit-box-shadow: 0px 0px 3px 0px #1e81ce; Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  /* -moz-box-shadow:    0px 0px 3px 0px #1e81ce;   Firefox 3.5 - 3.6 */
  /* box-shadow:         0px 0px 3px 0px #1e81ce;   Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.dropdown-menu {
  font-size: unset;
}

.dropdown-menu .show {
  transform: translate3d(16px, 188px, 6px) !important;
}

.circular--portrait {
  position: relative;
  float: left;
  top: 8px;
  width: 2vw;
  height: 2vw;
  overflow: hidden;
  border-radius: 50%;
}
.circular--portrait img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

 {
  /* <span className="account-user-avatar"> 
            <img src={this.state.profilePic} alt="user-image" className="rounded-circle" />
        </span> */
}
