.ruler {
  position: absolute;
  background-color: #eeeeee;
  background-image: linear-gradient(left, #808080 1px, transparent 1px),
    linear-gradient(left, #808080 1px, transparent 1px),
    linear-gradient(left, #808080 1px, transparent 1px);
  background-size: 50px 50px, 10px 50px, 5px 50px;
  background-position: 0 0, 0px 10px, 0 12px;
  background-repeat: repeat-x;
  border-bottom: 1px solid #808080;
}
.ruler.h {
  top: 5px;
  left: 16px;
  width: 100%;
  height: 15px;
  box-shadow: -16px 0 0 0 #eeeeee;
}
.ruler.v {
  top: 0px;
  left: 16px;
  width: 100%;
  height: 15px;
  transform: rotateZ(90deg) rotateX(180deg);
  transform-origin: 0% 100%;
}
.guide {
  position: absolute;
}
.guide:after {
  display: block;
  content: "";
  position: absolute;
  background-color: #4affff;
}
.guide.v {
  width: 11px;
  height: 100%;
  /* left: 200px; */
  /* top: 0; */
  cursor: ew-resize !important;
  z-index: 999;
}
.guide.v:after {
  width: 1px;
  height: 100%;
  /* left: 200px; */
  /* top: 0; */
}
.guide.h {
  /* top: 90px; */
  height: 11px;
  width: 100%;
  /* left: 0px; */
  cursor: ns-resize !important;
  z-index: 999;
}
.guide.h:after {
  height: 1px;
  width: 100%;
}

::-webkit-scrollbar-thumb.on {
  background: #8b75ee !important;
  border: 0px none #ffffff;
  border-radius: 0px;
}

@media (min-width: 1500px) {
  .col-xl-13 {
    flex: 0 0 100%;
    max-width: 80%;
  }
}

/* BOTÃO QUE PISCA */
@keyframes blink {
  0% {
    background-color: rgba(255, 0, 0, 1);
  }
  50% {
    background-color: rgba(255, 0, 0, 0.5);
  }
  100% {
    background-color: rgba(255, 0, 0, 1);
  }
}
@-webkit-keyframes blink {
  0% {
    background-color: rgba(255, 0, 0, 1);
  }
  50% {
    background-color: rgba(255, 0, 0, 0.5);
  }
  100% {
    background-color: rgba(255, 0, 0, 1);
  }
}

.download {
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-animation: blink normal 1.5s infinite ease-in-out;
  /* Firefox */
  -webkit-animation: blink normal 1.5s infinite ease-in-out;
  /* Webkit */
  -ms-animation: blink normal 1.5s infinite ease-in-out;
  /* IE */
  animation: blink normal 1.5s infinite ease-in-out;
  /* Opera */
}
/* BOTÃO QUE PISCA */
