// https://getbootstrap.com/docs/4.1/getting-started/theming/#css-variables
// Override default variables before the import
// $body-bg: #f4a01da1;
// $body-bg: ghostwhite;

$body-bg: #c3c0c0;

// Alterando a cor do botão info
$theme-colors: (
  "info": #755ce6,
);

body {
  overflow: hidden !important;
  // overflow: inherit !important;
  margin: 0 !important;
  padding: 0 !important;
  font-family: Roboto;
}

::-webkit-scrollbar-thumb {
  background: unset !important;
  border: 0px none #ffffff;
  border-radius: 0px;
}

.content::-webkit-scrollbar-thumb {
  // background: #8b75ee !important;
  background: #b7d5f9 !important;
  border: 0px none #ffffff;
  border-radius: 0px;
}

// switch Button
// .custom-control-input ~ .custom-control-label::after {
//   border-color: orangered !important;
//   background-color: orangered !important;
// }

// .custom-control-input:checked ~ .custom-control-label::after {
//   border-color: white !important;
//   background-color: white !important;
// }

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #755ce6 !important;
  background-color: #755ce6 !important;
}
// switch Button

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
// @import '~devextreme/dist/css/dx.common.css';
// @import '~devextreme/dist/css/dx.light.css';

// Altera a cor e texto dos botões de upload.
.custom-file-input ~ .custom-file-label::after {
  content: "Carregar" !important;
  background-color: #755ce6;
  color: white;
}

// Grupo de Campos do Devexpress no seu form
.dx-fieldset {
  margin: 5px 0px 20px 0px !important;
  padding: 0;
}

// CSS DA LABEL DE DESCONTO
.discount {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  margin: 5px auto;
  position: relative;
  display: inline-block;
  background: red;
  width: auto;
  height: 30px;
  line-height: 32px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 0 15px;
}
.discount:after {
  content: " ";
  height: 22px;
  width: 22px;
  background: red;
  position: absolute;
  top: 4px;
  left: -9px;
  border-radius: 4px;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.discount:before {
  content: "";
  width: 7px;
  height: 7px;
  background: #fff;
  position: absolute;
  top: 12px;
  left: 0px;
  z-index: 1;
  border-radius: 10px;
}

// Switch CheckBox mudar cor quando utilizar
.dx-switch-on-value .dx-switch-handle:before {
  background-color: rgb(48, 107, 50) !important;
}
.dx-switch-handle:before {
  display: block;
  content: " ";
  width: 100%;
  height: 100%;
  background-color: rgb(110, 110, 110) !important;
  border-radius: 2px;
}

// Efeito de LED
// .led-green {
//   margin: 0 auto;
//   width: 14px;
//   height: 14px;
//   background-color: #ABFF00;
//   border-radius: 50%;
//   box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px

// }

// .led {
//   margin: 20px auto;
//   width: 10px;
//   height: 10px;
//   border-radius: 50%;
//   background-color: rgba(255, 255, 255, 0.25);

// }
// .led-red {
//   background-color: #F00;
//   box-shadow: #000 0 -1px 6px 1px, inset #600 0 -1px 8px

// }

/// TESTE LED COM BUTTON
.wrapper {
  width: 500px;
  height: 80px;
  margin: 100px auto 0;
  border-radius: 10px;
  padding: 0.7%;

  background-image: -webkit-linear-gradient(top, #161616 0%, #1f1f1f 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#161616),
    to(#1f1f1f)
  );
}

.button_ {
  height: 100%;
  color: #818181;
  background-image: linear-gradient(top, #383838 0%, #1f1f1f 5%, #161616 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#1f1f1f),
    to(#161616)
  );
  border-radius: 7px;
  float: left;
  box-shadow: 0 0 1px 1px rgb(10, 10, 10);
  cursor: pointer;
  position: relative;
  font: normal 20px arial;
  text-transform: uppercase;
  // padding-left: 4%;
  // padding-top: 5%;
  vertical-align: bottom;
  transition: color 0.3s ease;
}

.button_:hover {
  color: #9e9e9e;
}

.button_:active {
  box-shadow: 0 0 1px 1px rgb(10, 10, 10), inset 0 2px 2px 0px black;
}

.button_:before {
  position: absolute;
  content: "";
  right: 15px;
  top: 10px;

  width: 12px;
  height: 12px;
  background: #646464;
  background-image: radial-gradient(top, #616161 0%, #616161 20%, #535353 60%);
  border-radius: 50%;
  box-shadow: 0 0 4px #1c1c17;
  transition: all 0.3s ease;
}

.button_:hover:before {
  background: #ff4f4f;
  box-shadow: 0 0 4px #1c1c17, 0 0 5px #ff4242;
}

.button_:active:before {
  box-shadow: 0 0 4px #1c1c17, inset 0 2px 2px 0px #cc0000;
}

.button_:nth-child(1) {
  width: 40%;
  margin-right: 2%;
}

.button_:nth-child(2) {
  width: 58%;
}

// Alterar posição e itens do Toast da Devexpress
.dx-overlay-content .dx-toast-success .dx-toast-content .dx-resizable {
  width: 700px !important;
  height: auto;
  z-index: 9501;
  margin: 0px;
  left: 0px;
  top: -236px;
  transform: translate(143px, 825px);
  transition: none 0s ease 0s;
  visibility: visible;
  opacity: 1;
}

.class_name {
  top: -236px !important;
}